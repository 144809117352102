<template>
  <div class="admin" v-if="!isProcessing">
    <admin-menu class="user__menu" v-for="item in items" :key="item.title" :item="item" />
  </div>
</template>

<script>
import AdminMenu from '@/components/common/menu.vue'
export default {
  name: 'admin',
  components: { AdminMenu },
  data () {
    return {
      items: [
        { title: '管理者権限の追加', content: '管理者ページへのアクセス権限を追加します。', to: 'admin_add' },
        { title: '管理者権限の削除', content: '管理者ページへのアクセス権限を削除します。', to: 'admin_delete' }
      ]
    }
  },
  mounted () {
    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Boolean} 処理中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';
@import '@/assets/sass/color.scss';

.admin {
  width: 100vw;
  max-width: $max_width;
  padding: $padding_width $padding_width;
  margin: $header_height auto 0 auto;
}
</style>
